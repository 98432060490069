import {motion} from 'framer-motion';
import {styles} from '../styles';
import {SectionWrapper} from '../hoc';
import React from "react";


const Family = () => {
    return (
        <div className="mt-12 bg-black-100 rounded-[20px]">
            <div className={`${styles.padding} bg-tertiary rounded-2xl min-h-[300px]`}>
                <motion.div>
                    <p className={`${styles.sectionSubText} opacity-100 jcblue-text`}>
                        What matters most
                    </p>
                    <h2 className={`${styles.sectionHeadText} opacity-100`}>
                        Family.
                    </h2>
                </motion.div>
            </div>
            <div className={`${styles.paddingX} -mt-20 pb-14 flex flex-wrap gap-7 `}>
                Hey! I'm workin' over here!<br/>

            </div>
        </div>
    );
};

export default SectionWrapper(Family, "family")