import {Analytics} from '@vercel/analytics/react';
import {isMobile} from "react-device-detect";
import {BrowserRouter} from "react-router-dom";
import {
    About,
    Contact,
    EarthCanvas,
    Experience,
    Feedbacks,
    Hero,
    Hobbies,
    Navbar,
    Projects,
    StarsCanvas
} from './components'
import React from "react";

function App() {

    if (!isMobile) {
        return (<>  <BrowserRouter>
                <div className="relative z-0 bg-primary">

                    <div className="bg-hero-pattern rounded-2xl bg-cover bg-no-repeat bg-center">
                        <Navbar/>
                    </div>

                    <div className="rounded-b-3xl relative z-0">
                        <Hero/>
                        <StarsCanvas/>
                    </div>

                    <div className="relative z-0">
                        <About/>
                        <StarsCanvas/>
                    </div>

                    <div className="relative z-0">
                        <Experience/>
                        <StarsCanvas/>
                    </div>

                    <div className="relative z-0">
                        <Projects/>
                        <StarsCanvas/>
                    </div>

                    <div className="relative z-0">
                        <Feedbacks/>
                        <StarsCanvas/>
                    </div>

                    <div className="relative z-0">
                        <Hobbies/>
                        <StarsCanvas/>
                    </div>


                    <div className="relative z-0">
                        <EarthCanvas/>
                        <Contact/>
                        <div className="mt-4 mb-5 text-center">

                            <i>“Nobody knows really what they’re doing and there’s two ways to go with that information.
                                One is
                                to be afraid and the other is to be liberated, and I choose to be liberated by it.”
                            </i>
                        </div>
                        <div className="mt-4 mb-5 sm:h-[50px] text-center">
                            <em>- Conan O'Brien</em>
                        </div>
                        <StarsCanvas/>
                    </div>
                </div>
                <Analytics/>
            </BrowserRouter>
            </>

        );
    } else {
        return (<>  <BrowserRouter>
                <div className="relative z-0 bg-primary">

                    <div className="bg-hero-pattern rounded-2xl bg-cover bg-no-repeat bg-center">
                        <Navbar/>
                    </div>

                    <div className="rounded-b-3xl relative z-0">
                        <Hero/>
                    </div>

                    <div className="relative z-0">
                        <About/>
                        <Experience/>
                        <Projects/>
                        <Feedbacks/>
                        <Hobbies/>
                        <Contact/>
                    </div>

                    <div className="relative z-0">
                        <div className="mt-4 mb-5 text-center">

                            <i>“Nobody knows really what they’re doing and there’s two ways to go with that information.
                                One is
                                to be afraid and the other is to be liberated, and I choose to be liberated by it.”
                            </i>
                        </div>
                        <div className="mt-4 mb-5 sm:h-[50px] text-center">
                            <em>- Conan O'Brien</em>
                        </div>
                    </div>
                </div>
                <Analytics/>
            </BrowserRouter>
            </>

        );
    }
}

export default App;
