import {motion} from 'framer-motion';

import {Tilt} from 'react-tilt';

import {styles} from '../styles';
import {SectionWrapper} from '../hoc';
import {hobbies} from "../constants";

const HobbyCard = ({index, name, description, tags, image, source_code_link}) => (
    <motion.div className="flex">
        <Tilt
            options={{
                max: 5,
                scale: 1,
                speed: 450
            }}
            className="bg-tertiary opacity-80 p-5 rounded-2xl sm:w-[320px] w-full jcblue-gradient"
        >
            <div className="relative w-full h-[230px] ">
                <img src={image} alt={name} className="w-full h-full object-cover-rounded-2xl"/>
                <div className="absolute inset-0 flex justify-end m-3 card-img_hover">


                    { /*<div
                onClick={() => window.open
                (source_code_link, "_blank")}
                className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
              >
                  <img src={github} alt="github" className="w-1/2 h-1/2 object-contain" />

              </div> */}
                </div>
            </div>
            <div className="mt-5">
                <h3 className="text-white font-bold text-[24px]">{name}</h3>
                <p className="mt-2 text-secondary text-[14px]">{description}</p>
            </div>
            <div className="mt-4 flex flex-wrap gap-2 ">
                {tags.map((tag) => (
                    <p key={tag.name} className={`text-[14px] ${tag.color}`}>
                        #{tag.name}
                    </p>
                ))}
            </div>
        </Tilt>
    </motion.div>
)
const Hobbies = () => {
    return (
        <>
            <div className="rounded-[20px] -mt-14">
                <div className={`${styles.padding} min-h-[300px] `}>
                    <motion.div>
                        <p className={`${styles.sectionSubText} jcblue-text`}>A time to recharge</p>
                        <h2 className={`${styles.sectionHeadText}`}>Play</h2>
                    </motion.div>
                    <div><p className="text-[17px] text-secondary w-full mt-3">
                        I have many interests, and try to spend time doing things that I find inspiring or challenge me
                        in some way.
                    </p>
                    </div>

                </div>
                <div className={`${styles.paddingX} -mt-10 pb-14 flex flex-wrap gap-7 `}>

                    {hobbies.map((hobby, index) => (
                        <HobbyCard
                            key={hobby.name}
                            index={index}
                            {...hobby}
                        />
                    ))}

                </div>
            </div>
        </>
    );
};

export default SectionWrapper(Hobbies, "hobbies")