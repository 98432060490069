import React from "react";
import {SectionWrapper} from '../hoc';
import {motion} from 'framer-motion';
import {styles} from '../styles';
import {projects} from '../constants';


const ProjectCard = ({index, name, description, tags, image, source_code_link}) => {
    return (
        <motion.div className="flex">

            <div className="bg-tertiary p-5 rounded-2xl sm:w-[320px] w-full jcblue-gradient">
                <div className="relative w-full h-[230px]">
                    <img src={image} alt={name} className="w-full h-full object-cover-rounded-2xl"/>
                    <div className="absolute inset-0 flex justify-end m-3 card-img_hover">
                    </div>
                </div>
                <div className="mt-5">
                    <h3 className="text-white font-bold text-[24px]">{name}</h3>
                    <p className="mt-2 text-secondary text-[14px]">{description}</p>
                </div>
                <div className="mt-4 flex flex-wrap gap-2">
                    {tags.map((tag) => (
                        <p key={tag.name} className={`text-[14px] ${tag.color}`}>
                            #{tag.name}
                        </p>
                    ))}
                </div>
            </div>
            {/*</Tilt>*/}
        </motion.div>
    )
}
const Projects = () => {
    return (
        <>
            <div className="rounded-[20px] -mt-14">
                <div className={`${styles.padding} min-h-[300px] `}>
                    <motion.div>
                        <p className={`${styles.sectionSubText} jcblue-text`}>Results Matter</p>
                        <h2 className={styles.sectionHeadText}>Projects</h2>
                    </motion.div>
                </div>
                <div
                    className={`${styles.paddingX} -mt-28 pb-14 flex flex-wrap gap-7 `}
                >
                    <div className="w-full flex">
                        <motion.p

                            className="mt-1 text-secondary text-[17px] max-w-7xl leading-[30px]">
                            The following projects are a few of my favorites and showcase my skills and experience
                            across a wide range of industries, disciplines and roles. Each project includes a brief
                            description and the technologies that were selected.
                            For a more up to date and complete list of projects, please see my&nbsp;
                            <u>
                                <a
                                    rel="noreferrer"
                                    className="text-blue-400 cursor-pointer hover:text-white"
                                    target="_blank"
                                    href="https://www.linkedin.com/in/gregorysmithdataengineer">
                                    LinkedIn profile
                                </a>
                            </u> or my&nbsp;
                            <u>
                                <a
                                    rel="noreferrer"
                                    className="text-blue-400 cursor-pointer hover:text-white"
                                    target="_blank"
                                    href="https://www.github.com/OryGregS">
                                    GitHub profile
                                </a>
                            </u>.
                        </motion.p>
                    </div>
                    <div className="mt-4 flex flex-wrap gap-7">
                        {projects.map((project, index) => (
                            <ProjectCard
                                key={`project-${index}`}
                                index={index}
                                {...project}
                            />
                        ))}

                    </div>
                </div>
            </div>
            {/*</div>*/}
        </>
    )
}

export default SectionWrapper(Projects, "projects");
