import {
    backend,
    blankuser,
    creator,
    css, data_migration,
    docker,
    effologo,
    garminlogo,
    gdcirclelogo,
    git,
    guitars,
    coming_soon,
    html,
    javascript,
    mobile,
    mongodb,
    nodejs,
    portfolio,
    salespagelogo,
    web,
    data_stream,
    steam_logo,
    iceberg,
    marketing_data,
    garmin_insights,
    fuzzy_match, twiliologo,
} from "../assets";

export const topLinks = [
    {
        id: "test1",
        title: "Fancy",
    },
    {
        id: "test2",
        title: "Clean",
    }
];
export const navLinks = [
    {
        id: "about",
        title: "About",
    },
    // {
    //     id: "family",
    //     title: 'Family',
    // },
    {
        id: "experience",
        title: "Experience",
    },
    {
        id: "projects",
        title: "Projects",
    },
    {
        id: "hobbies",
        title: 'Hobbies',
    },
    {
        id: "contact",
        title: "Contact",
    },
];

const hobbies = [
    /*{
        name: "Woodworking",
        description:
            "I enjoy creating boxes, wood art, scroll sawing and otherwise building or testing out concepts I read about.  I am a huge fan of dovetails, and so utilize that technique in a lot of my work.",
        tags: [
            {
                name: "dovetails",
                color: "white-text-gradient",
            },
            {
                name: "lie-nielsen",
                color: "green-text-gradient",
            },
            {
                name: "bluespruce",
                color: "blue-text-gradient",
            },
        ],
        image: woodworking_highlight,
    },
    {
        name: "The great outdoors",
        description:
            "I enjoy getting out in nature, and going hiking, kayaking, canoeing, etc whenever we can fit it in.  Kim, I and the girls have hit various parks and rivers across Ohio.  We've also been known to take time to explore a new park, beach or area while on vacation.  Coming to a state or park near you!",
        tags: [

            {
                name: "hiking",
                color: "green-text-gradient",
            },
            {
                name: "canoeing",
                color: "orange-text-gradient",
            },
            {
                name: "nature",
                color: "green-text-gradient",
            },

        ],
        image: outdoors,

    },
    {
        name: "Home Updates",
        description:
            "I am a big fan of doing things right, and so when it comes to our home I can be very particular.  I like to take my time to understand the code and requirements; and call in the pro's when its needed.  Otherwise, I generally tackle a lot of things myself.",
        tags: [

            {
                name: "festool",
                color: "green-text-gradient",
            },
            {
                name: "homerepair",
                color: "orange-text-gradient",
            },
            {
                name: "diy",
                color: "blue-text-gradient",
            },

        ],
        image: teakwood_renovation,

    },
    {
        name: "Yard Work",
        description:
            "I spend a lot of time in the yard, not always succeeding but always trying to make it look a little better.  So far in our new house, I've planted many willows, long grasses, shrubs and most recently planted Wisteria. ",
        tags: [
            {
                name: "yardwork",
                color: "green-text-gradient",
            },
            {
                name: "flowers",
                color: "pink-text-gradient",
            },
            {
                name: "gardens",
                color: "blue-text-gradient",
            },

        ],
        image: yardwork,

    },*/
    {
        name: "Guitar",
        description:
            "My favorite hobby! I currently have 4 guitars: a Martin acoustic, Schecter electric 6-string, Jackson electric 7-string, and a Legator 8-string.",
        tags: [
            {
                name: "Schecter",
                color: "white-text-gradient",
            },
            {
                name: "Martin",
                color: "green-text-gradient",
            },
            {
                name: "Jackson",
                color: "blue-text-gradient",
            },
            {
                name: "Legator",
                color: "orange-text-gradient",
            },
        ],
        image: guitars,
    },
    {
        name: "More to come!",
        description:
            "",
        tags: [
            {
                name: "coming soon",
                color: "orange-text-gradient",
            },
        ],
        image: coming_soon,
    },
];
const services = [
    {
        title: "Leadership",
        icon: creator,
    },
    {
        title: "Data",
        icon: backend,
    },
    {
        title: "Web",
        icon: web,
    },
    {
        title: "Cloud",
        icon: mobile,
    },

];

const technologies = [
    {
        name: "HTML 5",
        icon: html,
    },
    {
        name: "CSS 3",
        icon: css,
    },
    {
        name: "JavaScript",
        icon: javascript,
    },

    {
        name: "Node JS",
        icon: nodejs,
    },
    {
        name: "MongoDB",
        icon: mongodb,
    },
    {
        name: "git",
        icon: git,
    },
    {
        name: "docker",
        icon: docker,
    },
];

const experiences = [
    {
        title: "Sr Software Engineer",
        company_name: "Twilio Segment",
        link: "https://www.twilio.com/en-us",
        icon: twiliologo,
        iconBg: "#6addb2",
        date: "February 2024 - Right now at this very moment & you're now aware of your breathing lol",
        points: [
            "Saved over $1m in projected annual Databricks usage costs by working closely with external field engineers to fine-tune ~50 Databricks Jobs responsible for ingesting/maintaining a sustained 500k records-per-second into a multi-petabyte Delta Lakehouse that powers Segment's customer data profiles across all product offerings.",
            "Drove down AWS S3 costs (storage + API requests) for the Profiles Data Lake by ~30%.",
            "Owned operational readiness and excellence for Profiles Data Lake migration, ensuring a resilient Data Platform complete with: real-time systems monitoring via DataDog; custom dashboard panels; Slack alerting; GDPR/CCPA/BCR compliance; PagerDuty integration; & internal documentation/runbooks.",
            "Introduced & productionalized concurrent patterns in Scala/Spark allowing multiple tenants/customers to process at the same time; ultimately decreasing job runtimes by 44% on average while increasing cluster worker/executor CPU utilization by 8x on compute-heavy tasks.",
            "Assisted in the initial setup of a cloud-to-cloud migration process using Python, Apache Airflow, GCP, AWS (Athena, Glue, MWAA, S3, Step Functions, Data Sync), Scala, and Databricks; ultimately allowing our team to automate our migration & backfill processes."
        ],
    },
    {
        title: "Sr Data Engineer",
        company_name: "84.51°",
        link: "https://www.8451.com",
        icon: effologo,
        iconBg: "#532182",
        date: "June 2023 - November 2023",
        points: [
            "Owned the full SDLC of critical-path migration efforts for an at-risk multi-million dollar investment/acquisition, highlights include: moving & validating 50TB of data between IBM Netezza and Snowflake via bespoke PySpark applications designed to be performant & highly cost-effective (<$10k) with zero interruption of live customer traffic; orchestration of cloud-based workflows via ADF & Databricks; the creation of testing frameworks/harnesses; and automation of manual QA processes.",
            "Mentored junior & senior colleagues on technical topics such as Spark configuration/tuning; object-oriented & functional paradigm concepts in Python and when/why/how they’re useful; Snowflake performance/cost traps to avoid & optimizations to pursue; solution/software/system design; and engineering principles such as DRY, SOLID, and test-driven development (TDD).",
            "Initiated cross-functional collaboration with key decision-makers & stakeholders belonging to several disparate teams within the enterprise to introduce hybrid-cloud capabilities in Azure to pave the way for many future on-premises-to-cloud migrations.",
            "Accelerated development & progress of a legacy data warehouse migration project by 8x over the previously failed attempt(s) through strong leadership, communication, & technical skills as well as championing adoption of new channels of communication, ways of working, and best practices."
        ],
    },
    {
        title: "Sr Data Engineer",
        company_name: "Glassdoor",
        link: "https://www.glassdoor.com/Community/index.htm",
        icon: gdcirclelogo,
        iconBg: "#0CAA41",
        date: "August 2022 - March 2023",
        points: [
            "Led team of 4 engineers to build a proof-of-concept lakehouse architecture using Apache Iceberg, S3 and Apache Spark; highlights from the team’s company-wide Hackathon presentation include (compared to Hive & ORC): ~$700,000 annual AWS savings mainly via reduction in data storage costs; showcasing Iceberg’s promising improvements in functionality/performance (zstd-compressed Parquet); and deep dives & visualizations of Iceberg features such as time travel, hidden partitioning, & schema/partition evolution.",
            "Spearheaded efforts to improve the data and ML teams’ Spark, Airflow, and Python development workflows by hosting presentations, advocating for best practices, & alleviating common cross-functional pain points which led to huge boosts in productivity across the overall data team.",
            "Designed, developed, tested, & maintained mission-critical, petabyte-scale data pipelines powering executive-level reports & dashboards using technologies such as Spark, Hive, and Airflow; all backed by AWS EMR & S3."
        ],
    },
    {
        title: "Software Engineer II | Big Data Team",
        company_name: "Garmin",
        icon: garminlogo,
        iconBg: "#fff",
        date: "February 2021 - August 2022",
        points: [
            "Migrated Garmin’s Java-based Apache Storm applications to Apache Flink which (in conjunction with Apache Kafka) powers real-time fitness & wellness insights as well as dynamic workout coaching for users worldwide; highlights include: achieving 95% increased test coverage, implementing real-time Grafana dashboards that monitor custom StatsD metrics, and configuring automated alerting for maintainability.",
            "Reorganized, took full inventory of, & upgraded team’s Java/Spring API’s, Java MapReduce jobs, Git repositories, Jenkins jobs/pipelines, bash scripts, & cron-based schedulers to allow for rapid execution of critical software & security improvements/mandates under tight timelines; ultimately accelerating the pace of the team’s larger initiatives to migrate their on-premises Hortonworks Data Platform (HDP) to Cloudera Data Platform (CDP).",
            "Owned the big data team’s internal data request/analysis process which utilized technologies such as Scala, Apache Spark, Parquet, AVRO; Protobuf, HDFS, & Apache HBase; all to quickly deliver data-driven decisions to various key company stakeholders with an average of 5 day turnaround.",
            "Generated upwards of 10% increased device sales by working with MarCom teams to successfully execute & deliver unique coupon codes and ads for ”up-sell” campaigns that provided targeted savings to specific demographics of Garmin’s user-base (generally active users wearing older device models).",
            "Supported customer-facing features by identifying the root cause of escalated customer issues for Garmin Coach & Insights and applying both short-term & long-term fixes."
        ],
    },
    {
        title: "Data Engineer",
        company_name: "SalesPage Technologies",
        icon: salespagelogo,
        iconBg: "#fff",
        date: "March 2019 - February 2021",
        points: [
            "Built Java-based MDM data pipelines hosted on AWS for a new managed SaaS product offering (LumaSuite Cloud) while collaborating with cross-functionally with two separate teams and C-level executives to successfully launch the service from beta to production in under 1 year.",
            "Debugged, performed root cause analysis of, and ultimately addressed major performance pain points for SalesPage’s Java (ORM) ELT pipelines; ultimately recorded ~600% mean speed-up (post-fix) which greatly accelerated development & testing cycles to reach project deadlines.",
            "Developed a platform-wide fuzzy matching solution in Java and SQL Server that enriched data insights by linking ~15% of unaccounted-for data; this solution achieved a 98.9% match rate on two sets of 1,000,000 records in under 10 seconds through careful implementation of various algorithms such as Levenshtein distance, Soundex, & Metaphone.",
            "Implemented eﬀicient bulk data quality & cleansing processes in Java & SQL Server that standardizes data flowing through all ETL pipelines.",
            "Drove down the total time of a client’s vital data migration/conversion from ~4 days to ~12 hours by learning Oracle & batch scripting on the fly and automating the largely manual process to an optimized, parallel data process using batch scripts, Oracle, & SQL Server."
        ],
    }
];

const testimonials = [
    {
        testimonial:
            "Greg is a fantastic resource and just all around good people." +
            "When Greg started, he immediately hit the ground running, asked relevant questions along the way and just got stuff done. He was un-afraid to challenge views, understand the implementation, put in the hard work, and reach for the stars! \n" +
            "We partnered with Greg on a number of initiatives during his time at Glassdoor, including working with Data Platform leads and engineers to build out a POC Iceberg implementation for our Hackathon. It was a fantastic project and subsequent presentation that really demonstrated the increased performance, storage and cost controls that could be achieved by switching many of our tables over to using Iceberg.\n" +
            "I would hire Greg in a heartbeat and you should too!",
        name: "Jesse Charbneau",
        linkedin: "https://www.linkedin.com/in/jcharbneau/",
        designation: "Senior Manager, Data Platform Services",
        company: "Glassdoor",
        image: blankuser,
    },
    {
        testimonial:
            "I had the privilege of working with Greg for a time at 84.51. I highly recommend Greg when it comes to his technical skills and abilities. He has a wealth of knowledge with regard to backend cloud architecture and systems. He also is able to solve difficult problems in multiple coding languages and using multiple cloud technologies. He was an asset to our team in many ways and I am happy to recommend him to any company or team that could benefit from his type of expertise.\n" +
            "\n" +
            "I will also say that he's a fun guy to work with. He has a great sense of humor but knows when to be serious. He knows how to get his stuff done on time. He's also very communicative and has no problem asking questions to better understand a problem or situation. He also impressed me by continually reaching out for feedback, not only on his performance, but his fit with the team. He is very teachable and eager to learn and grow as much as he is eager to share his ideas and fight for best practices.",
        name: "Mark Griggs",
        linkedin: "https://www.linkedin.com/in/markgriggs/",
        designation: "Lead Data Engineer",
        company: "84.51°",
        image: blankuser,
    }
];
const projects = [
    {
        name: "Supply Chain | IBM Netezza → Snowflake Migration | 84.51°",
        description:
            "Led the planning, design, and development of bespoke PySpark applications responsible for moving data between Netezza (on-prem) to Snowflake (cloud) to support critical migration efforts.",
        tags: [
            {
                name: "azure",
                color: "blue-text-gradient",
            },
            {
                name: "hadoop",
                color: "orange-text-gradient",
            },
            {
                name: "python",
                color: "pink-text-gradient",
            },
            {
                name: "apache spark",
                color: "white-text-gradient"
            },
            {
                name: "yarn",
                color: "yellow-text-gradient"
            }
        ],
        image: data_migration,
        source_code_link: "",
    },
    {
        name: "Personal Project | Portfolio Website (v3)",
        description:
            "Forked from Jesse Charbneau's website (jessecharbneau.com), this website serves as a testament to who I am and what I have achieved. This is the first version of my portfolio website that isn't static & I have a lot of Javascript left to learn!",
        tags: [
            {
                name: "reactjs",
                color: "white-text-gradient",
            },
            {
                name: "threejs",
                color: "green-text-gradient",
            },
            {
                name: "html5",
                color: "pink-text-gradient",
            },
            {
                name: "css",
                color: "blue-text-gradient",
            },
            {
                name: "portfolio",
                color: "orange-text-gradient",
            },

        ],
        image: portfolio,
        source_code_link: "https://github.com/",
    },
    {
        name: "Social Media & HR Tech | Event-stream Analytics | Glassdoor/Fishbowl",
        description:
            "As Glassdoor acquired Fishbowl, the data team was challenged with ingestion of (even more) event-stream data. Ingested petabytes of user events such as clicks, reactions, & impressions as well as implemented several features for more granular analysis.",
        tags: [
            {
                name: "AWS",
                color: "white-text-gradient",
            },
            {
                name: "hive",
                color: "green-text-gradient",
            },
            {
                name: "apache spark",
                color: "pink-text-gradient",
            },
            {
                name: "SQL",
                color: "blue-text-gradient",
            },
            {
                name: "apache airflow",
                color: "orange-text-gradient",
            },
            {
                name: "python",
                color: "red-text-gradient",
            },
        ],
        image: marketing_data,
        source_code_link: "https://github.com/",
    },
    {
        name: "Social Media & HR Tech | Apache Iceberg PoC & Deep Dive | Glassdoor/Fishbowl",
        description: "Led hackathon team of 4 engineers to analyze and propose adopting Apache Iceberg. Performed cost analysis, feature analysis, and benchmarked performance on our datasets.",
        tags: [
            {
                name: "AWS",
                color: "white-text-gradient",
            },
            {
                name: "apache spark",
                color: "green-text-gradient",
            },
            {
                name: "apache iceberg",
                color: "pink-text-gradient",
            },
            {
                name: "SQL",
                color: "blue-text-gradient",
            },
            {
                name: "hackathon",
                color: "orange-text-gradient",
            },

        ],
        image: iceberg,
        source_code_link: "https://github.com/",
    },
    {
        name: "Fitness Tech | CDP → HDP Migration | Garmin",
        description: "Led efforts to inventory, upgrade, and refactor user-facing features such as Garmin Coach & Insights which both supply customers with real-time fitness/wellness feedback & tips.",
        tags: [
            {
                name: "java",
                color: "white-text-gradient",
            },
            {
                name: "spring boot",
                color: "green-text-gradient",
            },
            {
                name: "apache flink",
                color: "pink-text-gradient",
            },
            {
                name: "apache kafka",
                color: "blue-text-gradient",
            },
            {
                name: "rest",
                color: "orange-text-gradient",
            },

        ],
        image: garmin_insights,
        source_code_link: "https://github.com/",
    },
    {
        name: "Financial Tech | Fuzzy Matching | SalesPage",
        description: "Created a novel algorithm to rank similar entities (Firm, Office, Rep) based on average weighted string similarity (Levenshtein) between 2 records.",
        tags: [
            {
                name: "java",
                color: "white-text-gradient",
            },
            {
                name: "SQL",
                color: "green-text-gradient",
            },
            {
                name: "maven",
                color: "pink-text-gradient",
            },

        ],
        image: fuzzy_match,
        source_code_link: "https://github.com/",
    },
    {
        name: "More to come!",
        description:
            "",
        tags: [
            {
                name: "coming soon",
                color: "orange-text-gradient",
            },
        ],
        image: coming_soon,
    },
];

export {services, technologies, experiences, testimonials, projects, hobbies};