import {motion} from 'framer-motion';
import {styles} from '../styles';
import {SectionWrapper} from '../hoc'
import React from "react";

const WhatsNext = () => {
    return (
        <>
            <div className="rounded-[20px] -mt-14">
                <div className={`${styles.padding} min-h-[300px] `}>
                    <motion.div>
                        <p className={`${styles.sectionSubText} jcblue-text`}>The Future is Now</p>
                        <h2 className={styles.sectionHeadText}>Work</h2>
                    </motion.div>
                </div>
                <div
                    className={`${styles.paddingX} -mt-20 pb-14 flex flex-wrap gap-7 `}
                >
                    <motion.p
                        className="mt-4 text-secondary text-[17px] max-w-6xl leading-[30px]"
                    >

                        {/*What do I want to do when I grow up?   I think of this question often, and I have to say I'd like to keep moving forward working with teams across organizations to facilitate application, web and data analytics. There are so many opportunities in the data engineering landscape; from platform development and management, to high speed ingestion, to machine learning and AI initiatives to development maturity using continuous integration and deployment models.  It is a fantastic time to be in the data space!  Every vertical in data engineering is evolving, and sometimes at break neck speed.*/}

                        {/*<p className="text-[17px] text-secondary w-full mt-5">*/}
                        {/*     providing self-service capabilities for engineers, business leaders and teams needing access to data and analytics services.*/}
                        {/*</p>*/}
                        <span className="text-[17px] text-secondary w-full mt-5">
              I am interested in engineering leadership roles in the data space.  With my experience managing teams, leading large scale projects, designing systems and working through and successfully completing complex initiatives; I am able to offer many insights and skills for businesses looking to leverage cloud technologies in the data &amp; analytics spaces.
              </span><br/><br/>
                        <span className="text-[17px] text-secondary w-full mt-5">
              I am at my best when helping others, recruiting talented engineers, helping teams develop their skills &amp; careers,  working to further technology initiatives, and building systems and processes at scale.
          </span><br/><br/>
                        <span className="text-[17px] text-secondary w-full mt-5">

          I enjoy collaborating with cross functional teams, understanding their needs and working with engineers and analysts to further the conversation; the innovation and the business &amp; consumer insights that properly designed data structures, processes and systems can provide.

          </span>

                        <br/><br/>
                        <span className="mt-8  w-full">Learn more about my work related ...</span>
                        <span className="mt-2 inline-flex text-center w-full ">

               <span className="w-1/3">
                  <a href="#experience" className="cursor-pointer hover:text-blue-500">
                      <span
                          className="w-full h-full justify-center inline-flex p-2 border-tertiary hover:bg-tertiary bg-primary">
                     Experience
                      </span>
                  </a>
              </span>
              <span className="w-1/3">
                 <a href="#projects" className="cursor-pointer hover:text-blue-500 ">

                     <span
                         className="w-full h-full justify-center inline-flex p-2 border-tertiary hover:bg-tertiary bg-primary">
                      Projects
                     </span></a>
              </span>

              <span className="w-1/3">
                  <a href="#testimonials" className="cursor-pointer hover:text-blue-500">
                      <span className="w-full h-full justify-center inline-flex p-2 hover:bg-tertiary bg-primary">
                     Testimonials
                      </span>
                  </a>
              </span>
          </span>
                        {/*<br/><br/>*/}
                        {/*<span className="text-[17px] text-secondary w-full mt-4">*/}

                        {/*    For more details on my work and experience, please visit my &nbsp;*/}
                        {/*    <u>*/}
                        {/*        <a*/}
                        {/*            rel="noreferrer"*/}
                        {/*            className="cursor-pointer hover:text-white"*/}
                        {/*            href="https://www.linkedin.com/in/jcharbneau/" target="_blank">*/}
                        {/*            Profile*/}
                        {/*        </a></u>&nbsp; on LinkedIn.*/}
                        {/*</span>*/}
                    </motion.p>
                </div>
            </div>
        </>
    )
}

export default SectionWrapper(WhatsNext, "work")