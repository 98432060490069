import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {styles} from '../styles';
import {navLinks} from '../constants';
import {close, logo, menu} from '../assets';
import {SocialIcon} from "react-social-icons";


const Navbar = () => {
    const [active, setActive] = useState('');
    const [toggle, setToggle] = useState(false);


    return (
        <nav className={`${styles.paddingX} w-full flex items-center py-5 fixed top-0 z-40 bg-primary`}>
            <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
                <Link to="/"
                      className="flex items-center gap-2 "
                      onClick={() => {
                          setActive("");
                          window.scrollTo(0, 0)

                      }}>
                    <img src={logo} alt="logo" className="w-11 h-11 object-contain"/>
                    <p className="text-white text-[18px] font-bold cursor-pointer flex">
                        Greg Smith&nbsp;<span className="xl:block hidden">| Engineer | Architect | Technologist</span>
                    </p>

                </Link>
                <ul className="list-none hidden sm:flex flex-row gap-10">
                    {navLinks.map((Link) => (
                        <li
                            key={Link.id}
                            className={`${
                                active === Link.title
                                    ? "text-white"
                                    : "jcblue-text"
                            } hover:text-white text-[18px] font-medium cursor-pointer `}
                            onClick={() => setActive(Link.title)}
                        >
                            <a className="" href={`#${Link.id}`}>{Link.title}</a>
                        </li>
                    ))}
                    <div className="text-center flex flex-row h-fit w-fit gap-2">
                        <SocialIcon target="_blank" bgColor="#4A80FF" className="max-w-[32px] max-h-[32px]"
                                        network="linkedin" url="https://www.linkedin.com/in/gregorysmithdataengineer/"/>

                        <SocialIcon target="_blank" bgColor="#4A80FF" className="max-w-[32px] max-h-[32px]"
                                    network="github" url="https://github.com/OryGregS"/>
                    </div>

                </ul>
                <div className="sm:hidden flex flex-1 justify-end items-center">
                    <img src={toggle ? close : menu}
                         alt="menu"
                         className="w-[28px] h-[28px] object-contain cursor-pointer "
                         onClick={() => setToggle(!toggle)}/>
                    <div
                        className={`${!toggle ? 'hidden' : 'flex'} p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}>
                        <ul className="list-none flex justify-end items-start flex-col gap-4">
                            {navLinks.map((Link) => (
                                <li
                                    key={Link.id}
                                    className={`${
                                        active === Link.title
                                            ? "text-white"
                                            : "jcblue-text"
                                    } font-poppins font-medium cursor-pointer text-[16px] jcblue-gradient `}
                                    onClick={() => {
                                        setToggle(!toggle);
                                        setActive(Link.title);
                                    }}
                                >
                                    <a href={`#${Link.id}`}>{Link.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </nav>
    )
}

export default Navbar