import React from 'react';
import {motion} from 'framer-motion';
import {styles} from '../styles';
import {fadeIn, textVariant} from "../utils/motion";
import {SectionWrapper} from '../hoc';

const About = () => {
    return (<>
        <div className="bg-black-100 opacity-80 rounded-[20px] -mt-10 flex">
            <div className={`${styles.padding} bg-tertiary rounded-2xl border-0 min-h-screen`}>
                <div className="">
                    <p className={`${styles.sectionSubText} jcblue-text`}>Introduction</p>
                    <h2 className={styles.sectionHeadText}>Overview</h2>
                </div>
                {/*
                <div variants={textVariant()} className="">
                    <p className={`${styles.sectionSubText} jcblue-text`}>Introduction</p>
                    <h2 className={styles.sectionHeadText}>Overview</h2>
                </div>
                */}

                <p
                    //variants={fadeIn("", "", 0.05, 1)}
                    className="mt-4 text-secondary text-[17px] max-w-6xl leading-[30px] flex"
                >
                        <span> I am a husband, a technologist, a tinkerer, and a passionate T-shaped problem solver in backend & data systems.
                          Currently, I live in Raleigh, NC with my wife, Becca, and our two cats, Koji & Koda.
                          However, I grew up in Zeeland/Holland, MI; which are 2 small twin towns right on the shoreline of Lake Michigan.


                            <br/><br/>
                            <span className="text-[17px] text-secondary w-full mt-5">
                              Ever since I was young (~10 yo), I have been involved with technology and have been the designated "IT Guy" of my extended family.
                              My parents always had a hard time keeping me off our family PC's as I spent a ridiculous amount of time playing PC games (thanks, World of Warcraft! :D).
                              Due to my interest in PC gaming at a very young age, I began learning about different areas of computing in order to "optimize" my gaming experience, such as: hardware & specifications, networking & ports, reading documentation, troubleshooting, and learning how to look up issues.
                            </span>


                            <br/><br/>
                            <span className="text-[17px] text-secondary w-full mt-5">
                                It wasn't until the beginning of my academic career @ WMU where I actually learned how to program/code.
                                While it was overwhelming at first, it turns out that I had somewhat of a natural talent for coding and loved that feeling you get when you <i>finally</i> solve a problem.
                                As coding and OOP came somewhat naturally to me, I was able to excel in Computer Science, Statistics, and Machine Learning; earning a Bachelor's of Science in Data Science with a minor in Computer Science.
                                Once I graduated, I realized I was burnt out doing statistics all day and that I had a <b>lot</b> more fun with the engineering side. I decided right then to pursue engineering roles instead of Data Science roles, and the rest (fortunately) fell right into place for me.
                                Out of undergrad, I was actually pursuing my Master's degree in Computer Science from Georgia Tech (OMSCS) and was enrolled full-time for one whole semester. Due to COVID-19 and the impact it had on the economy, I made the tough decision to drop out from GTech and focus on my marketable technical skills & my soft skills.
                                While getting my Master's degree is not on my short-term roadmap, I would love to pursue this in the future.
                            </span>

                            <br/><br/>
                            <span className="text-[17px] text-secondary w-full mt-5">
                                In my professional life, I am a highly technical leader; concentrated primarily in backend systems and data/analytics.
                                I have extensive experience delivering on complex initiatives, optimizing & automating processes, building and refactoring systems, and removing organization-wide pain points that slow down development.
                                In a team environment, I consider myself the&nbsp;
                                <a
                                    rel="noreferrer"
                                    className="text-blue-400 cursor-pointer hover:text-white"
                                    href="https://noidea.dog/glue"
                                    target="_blank">
                                    "<u>glue</u>"
                                </a>, and have always naturally assumed this role.
                                When I am tasked with a project or initiative, I do everything in my power to deliver the highest quality work possible.
                            </span>

                            <br/><br/>
                            <span className="text-[17px] text-secondary w-full mt-5">
                                In my personal life; my wife and I are big foodies and love traveling around to explore new sights, smells, & eats.
                                I also love to play guitar and am an avid student of the musical arts. Specific guitarists that inspire me are: Andy Mckee, Tommy Emmanuel, Tim Henson / Polyphia, Manuel Gardner Fernandes / Unprocessed, Ichika Nito, Charlie Robbins, and Eddie Van Der Meer.
                            </span>

                        </span>
                </p>
            </div>
        </div>
    </>)
}

export default SectionWrapper(About, "about")