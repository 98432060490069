import React, {useState} from "react";

import {styles} from '../styles';

//import herobg from '../assets/blue-galaxy-bg.jpg';
import bg_h from '../assets/city_h_mobile.jpg';
import bg_v_mobile from '../assets/city_v_mobile.jpg'
import {isMobile} from "react-device-detect";

const transitions = {


    designing: {
        text: "designing"
    },
    developing: {
        text: "developing"
    },
    improving: {
        text: "improving"
    },
    leading: {
        text: "leading the way to"
    },
    building: {
        text: "building"
    },
    dreaming: {
        text: "dreaming of"
    }
}
const Hero = () => {
    const [displayText, setText] = React.useState(
        transitions.designing.text
    );
    const [transitionKey, setTransitionKey] = React.useState(
        "designing"
    );


    const changeText = (text) => {
        setText(text)
    };

    React.useEffect(() => {
        const changeTransitionKey = (index) => {
            setTransitionKey(index)
        };

        const findNext = (key, obj) => {
            let keys = Object.keys(obj);
            return (keys[(keys.indexOf(key) + 1) % keys.length])
        }

        const intervalId = setInterval(
            () => {
                // let changed = false;
                let currentDisplay = {};
                let nextKey = findNext(transitionKey, transitions);
                changeTransitionKey(nextKey);

                currentDisplay = transitions[transitionKey];

                changeText(currentDisplay.text)

            },
            5000
        );

        return () => {
            clearTimeout(intervalId);
        }
    }, [transitionKey, displayText]);

    if (isMobile) {

        // eslint-disable-next-line react-hooks/rules-of-hooks


        let init_img_src;
        if (window.matchMedia("(orientation:portrait)").matches) {
            init_img_src = bg_v_mobile;
        } else {
            init_img_src = bg_h;
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [imageSrc, setImageSrc] = useState(init_img_src);

        window.matchMedia("(orientation:portrait)").addEventListener("change", e => {
            if (e.matches) {
                setImageSrc(bg_v_mobile);
            } else {
                setImageSrc(bg_h);
            }
        });


        return (

            <section className="relative w-full h-screen mx-auto mt-2 mb-5">
                <div
                    className="flex overflow-hidden absolute w-full h-screen opacity-100 bg-black-100 rounded-xl z-[0]  m-0 p-0"
                    style={{textAlign: "center", verticalAlign: "middle", display: "inline-block"}}>
                    <img src={imageSrc} alt={displayText} style={{zIndex: 10, padding: 0, margin: 0, marginLeft: 0}}
                         className="w-screen h-screen object-fill "/>
                </div>


                <div
                    className={`${styles.paddingX} border-0 absolute inset-0 top-[80px] max-w-7xl mx-auto flex flex-row items-start gap-5 opacity-100 `}>

                    <div
                        className="z-30 bg-black-200 rounded-xl pt-2 pb-2 pl-6 w-screen bg-opacity-40 bg-gradient-to-b  ">
                        <h1 className={`${styles.heroHeadText} text-white xs:text-xs text-3xl`}>Hi, I'm <span
                            className="text-[#EB11D7]">Greg!</span></h1>
                        <p className={`${styles.heroSubText} mt-2 text-left pr-4 pt-4 pb-4 text-white-100`}>
                            A technology enthusiast&nbsp;

                            <span
                                className="flex-none font-bold z-1 text-transparent text-2xl bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 inline-flex">
                              {displayText}
                          </span>

                            <br/>the big data universe.
                        </p>
                    </div>
                </div>
                <div
                    className="cursor-pointer absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center mt-5 pt-5 ">
                    <a href="#about" className="cursor-pointer container">
                        <div className="chevron_container ">
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                        </div>
                    </a>
                </div>
            </section>
        )
    } else {
        return (
            <section className="relative w-screen min-h-screen mx-auto mt-2 mb-5">
                <div
                    className="flex overflow-hidden absolute w-full h-screen opacity-75 gradient-to-r from-black-100 rounded-xl z-[0]  m-0 p-0"
                    style={{textAlign: "center", verticalAlign: "middle", display: "inline-block"}}>
                    <img src={bg_h} alt={displayText} style={{zIndex: 10, padding: 0, margin: 0, marginLeft: 0}}
                         className="w-screen h-screen object-fill "/>
                </div>

                <div
                    className={`${styles.paddingX} border-0 absolute inset-0 top-[15%] max-w-7xl mx-auto flex flex-row items-start gap-5 opacity-100 `}>
                    <div className="flex flex-col justify-center z-20 align-text-top items-center mt-0">
                        <div className="w-5 h-5 rounded-full bg-[#396ADB] m-0 "/>
                        <div className="w-1 sm:h-80 jcblue-pink-gradient h-auto justify-stretch "/>
                    </div>
                    <div
                        className="z-2 bg-black-200 rounded-xl pt-6 pb-12 pl-6 pr-6 w-screen bg-opacity-40 bg-gradient-to-b  ">
                        <h1 className={`${styles.heroHeadText} text-white xs:text-xs`}>Hi, I'm <span
                            className="text-[#EB11D7]">Greg!</span></h1>
                        <p className={`${styles.heroSubText} mt-2 text-center text-white-100`}>
                            A technology enthusiast&nbsp;

                            <span
                                className="flex-none font-bold z-1 text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-500 pb-2 to-purple-500 inline-flex">
                              {displayText}
                          </span>

                            &nbsp;the big data universe.
                        </p>
                    </div>
                </div>
                <div
                    className="cursor-pointer absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center mt-5 pt-5 ">
                    <a href="#about" className="cursor-pointer container">
                        <div className="chevron_container ">
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                        </div>
                    </a>
                </div>

            </section>


        )
    }
}

export default Hero