import {SectionWrapper} from '../hoc';
import {motion} from 'framer-motion';
import {styles} from '../styles';
import {testimonials} from "../constants";

const FeedbackCard = ({index, testimonial, name, designation, company, image}) => (
    <motion.div
        className="bg-black-200 p-10 rounded-3xl xs:w-100 w-full jcblue-gradient"
    >
        <p className="text-white font-black text-[48px]"></p>
        <div className="mt-1">
            <p className="text-white tracking-whiter text-[18px]">
                {testimonial}
            </p>
            <div className="mt-7 flex justify-between items-center gap-1">
                <div className="flex-1 flex flex-col">
                    <p className="text-white font-medium text-[16px]">
                        <span className="blue-text-gradient">@</span>{name}
                    </p>
                    <p className="mt-1 text-secondary text-[12px] blue-text-gradient">{designation} at {company}</p>
                </div>
                { /* <img
          src={image}
          alt={`feedback-by-${name}`}
          className="w-10 h-10 rounded-full object-cover"
        /> */}
            </div>
        </div>

    </motion.div>
)
const Feedbacks = () => {
    return (
        <>
            <div className="bg-black-100 opacity-80 rounded-[20px] -mt-10">
                <div className={`${styles.padding}  min-h-[300px]`}>
                    <motion.div>
                        <p className={`${styles.sectionSubText} jcblue-text`}>What others say</p>
                        <h2 className={`${styles.sectionHeadText}`}>Testimonials</h2>
                    </motion.div>

                    <div><p className="text-[17px] text-secondary w-full mt-3">
                        Below are some thoughts others have had of my work, my mentorship or for just being a good
                        colleague.
                    </p>
                    </div>
                    <div>
                        <p className="text-[17px] text-secondary w-full mt-4">

                            For more feedback on my work and experience, please visit my &nbsp;
                            <u>
                                <a
                                    rel="noreferrer"
                                    className="text-blue-400 cursor-pointer hover:text-white"
                                    href="https://www.linkedin.com/in/gregorysmithdataengineer/details/recommendations/"
                                    target="_blank">
                                    Recommendations Page
                                </a></u>&nbsp; on LinkedIn.
                        </p>
                    </div>
                </div>
                <div
                    className={`${styles.paddingX} -mt-10 pb-14 gap-7 `}
                >
                    {testimonials.map((testimonial, index) => (
                        <FeedbackCard
                            key={testimonial.name}
                            index={index}
                            {...testimonial}
                        />
                    ))}

                </div>
            </div>
        </>
    )
}

export default SectionWrapper(Feedbacks, "testimonials")